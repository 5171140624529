<div class="overlay" @fade></div>
<div class="background" [class.wide]="error === 'needEmail'">
  <div class="background-ripple" @ripple></div>
  <div class="background-border background-element" @plop></div>
  <div class="background-plain background-element" @plop2></div>
</div>
<div
  class="error-content"
  [class.wide]="error === 'needEmail'"
  [class.ci]="
    country === 'CI' && (error === 'ValidationInvalidPhoneNumber' || error === 'UnknownErrorSignIn')
  "
>
  <ng-container [ngSwitch]="error">
    <ng-container *ngSwitchCase="'AlreadyCreated'">
      <div class="already-login" i18n="@@UserAlreadyRegistered">error</div>
      <div class="login">{{ message }}</div>
      <a
        (click)="gotToForgotPassword()"
        i18n="Lien vers la page de récupération du mot de passe@@ForgottenPassword"
        >Mot de passe oublié ?</a
      >
    </ng-container>
    <ng-container *ngSwitchCase="'needEmail'">
      <div class="warning">!</div>
      <span i18n="@@PopupNeedEmail"
        >De acuerdo a nuestros nuevos términos y condiciones, agradecemos que agregues en el campo
        siguiente tu dirección de correo electrónico</span
      >
      <form #passwordRecoveryForm="ngForm" (ngSubmit)="onSubmit()">
        <mat-form-field>
          <input
            matInput
            type="email"
            name="email"
            i18n-placeholder="Placeholder du champs e-mail@@EmailPlaceHolder"
            placeholder="E-mail"
            [(ngModel)]="email"
            #emailControl="ngModel"
            required
            email
          />
          <mat-error
            *ngIf="emailControl.invalid"
            i18n="erreur de mauvais format d'email@@NotEmailError"
            >Vous devez renseigner un email</mat-error
          >
        </mat-form-field>
        <button
          class="button button-finger accent"
          [class.disabled]="passwordRecoveryForm.invalid"
          type="submit"
          i18n="@@Ok"
          *ngIf="!submitted; else loading"
        >
          Envoyer
        </button>
      </form>
    </ng-container>
    <ng-container *ngSwitchCase="'ConnexionIDAuto'" i18n="@@ConnexionIDAuto">error</ng-container>
    <ng-container *ngSwitchCase="'BadCredentials'" i18n="@@BadCredentials">error</ng-container>
    <ng-container *ngSwitchCase="'UnknownErrorSignIn'" i18n="@@UnknownErrorSignIn"
      >error</ng-container
    >
    <ng-container *ngSwitchCase="'ConnexionPasswordEmpty'" i18n="@@ConnexionPasswordEmpty"
      >error</ng-container
    >
    <ng-container *ngSwitchCase="'ConnexionLoginEmpty'" i18n="@@ConnexionLoginEmpty"
      >error</ng-container
    >
    <ng-container *ngSwitchCase="'AccountBanned'" i18n="@@AccountBanned">error</ng-container>
    <ng-container *ngSwitchCase="'AccountDeleted'" i18n="@@AccountDeleted">error</ng-container>
    <ng-container *ngSwitchCase="'BadLogin'" i18n="@@BadLogin">error</ng-container>
    <ng-container *ngSwitchCase="'EmailRequired'" i18n="@@EmailRequired">error</ng-container>
    <ng-container
      *ngSwitchCase="'ValidationInvalidPhoneNumber'"
      i18n="@@ValidationInvalidPhoneNumber"
      >error
    </ng-container>
    <ng-container *ngSwitchCase="'ValidationUniquePhoneNumber'" i18n="@@ValidationUniquePhoneNumber"
      >error
    </ng-container>
    <ng-container *ngSwitchCase="'NotEmailOrPhoneError'" i18n="@@NotEmailOrPhoneError"
      >error</ng-container
    >
    <ng-container *ngSwitchCase="'PseudoIsNumeric'" i18n="@@PseudoIsNumeric">error</ng-container>
    <ng-container *ngSwitchCase="'uniqueLogin'" i18n="@@uniqueLogin">error</ng-container>
    <ng-container *ngSwitchCase="'shortLogin'" i18n="@@shortLogin">error</ng-container>
    <ng-container *ngSwitchCase="'NotEmailError'" i18n="@@NotEmailError">error</ng-container>
    <ng-container *ngSwitchCase="'MyProfileEmailUnique'" i18n="@@MyProfileEmailUnique"
      >error</ng-container
    >
    <ng-container *ngSwitchCase="'badEmailFormat'" i18n="@@badEmailFormat">error</ng-container>
    <ng-container
      *ngSwitchCase="'PasswordRecuperationWrongEmailOrPhone'"
      i18n="@@PasswordRecuperationWrongEmailOrPhone"
      >error</ng-container
    >
    <ng-container
      *ngSwitchCase="'PasswordRecuperationWrongEmail'"
      i18n="@@PasswordRecuperationWrongEmail"
      >error
    </ng-container>
    <ng-container *ngSwitchCase="'ValidationRequiredEmail'" i18n="@@ValidationRequiredEmail"
      >error</ng-container
    >
    <ng-container *ngSwitchCase="'UnavailabilityService'" i18n="@@UnavailabilityService"
      >error</ng-container
    >
    <ng-container *ngSwitchCase="'NotEnoughCredit'" i18n="@@NotEnoughCredit"
      >error
      <!-- <ng-container i18n="@@NotEnoughCredit">error</ng-container>
      <button class="button button-auto lighten" i18n="@@GetCredit">Get credit</button> -->
    </ng-container>
    <ng-container *ngSwitchCase="'NonSubscriber'" i18n="@@NonSubscriber">error</ng-container>
    <ng-container *ngSwitchCase="'UserSuspended'"
      >Votre crédit semble insuffisant pour vous permettre d'utiliser le service en illimité. Merci
      de recharger votre crédit</ng-container
    >
    <ng-container *ngSwitchCase="'TooShortPassword'" i18n="@@TooShortPassword"
      >bad password {{ minLogin }} {{ maxLogin }}
    </ng-container>
    <ng-container *ngSwitchCase="'BadPassword'" i18n="@@BadPassword"
      >bad password {{ min }} {{ max }}</ng-container
    >
    <ng-container *ngSwitchDefault i18n="@@ConnexionErrorService">error</ng-container>
  </ng-container>
  <button
    class="button button-round accent"
    (click)="close()"
    i18n="@@Ok"
    *ngIf="error !== 'needEmail'"
  >
    ok !
  </button>
</div>
<ng-template #loading>
  <div class="tiny-loader" *ngIf="submitted && !success">
    <div class="tiny-loader-ripple">
      <div class="ripple ripple-left"></div>
      <div class="ripple ripple-right"></div>
    </div>
    <mat-icon svgIcon="email"></mat-icon>
  </div>
</ng-template>
